<template>
  <v-dialog :model-value="dialog" @update:model-value="updateDialog" max-width="600">
    <v-card>
      <v-card-title>
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <!-- Alert for displaying messages -->
        <v-alert
            v-if="alert.show"
            :type="alert.type"
            dismissible
            @input="alert.show = false"
        >
          {{ alert.message }}
        </v-alert>

        <v-list v-if="currentStep === 'type' || currentStep === 'subtype' || currentStep === 'sub-subtype'">
            <template v-if="currentStep === 'type'">
              <v-list-item
                  v-for="type in eventConfig.eventTypes"
                  :key="type.name"
                  @click="selectType(type)"
                  class="list-item-button"
              >
                  <v-list-item-title>{{ type.name }}</v-list-item-title>
              </v-list-item>
            </template>
            <template v-else-if="currentStep === 'subtype'">
              <v-list-item
                  v-for="subtype in currentSubtypes"
                  :key="subtype.name"
                  @click="selectSubtype(subtype)"
                  class="list-item-button"
              >
                <v-list-item-title>{{ subtype.name }}</v-list-item-title>
              </v-list-item>
            </template>
            <template v-else-if="currentStep === 'sub-subtype'">
              <v-list-item
                  v-for="subsubtype in currentSubtypes"
                  :key="subsubtype.name"
                  class="list-item-button"
              >
                  <v-list-item-title>
                    <v-checkbox
                        v-if="selectedSubtype.is_multichoice"
                        :label="subsubtype.name"
                        :value="subsubtype.name"
                        v-model="selectedSubSubtypes"
                    ></v-checkbox>
                    <v-radio
                        v-else
                        :label="subsubtype.name"
                        :value="subsubtype.name"
                        v-model="selectedSubSubtype"
                        @change="goToMapStep"
                    ></v-radio>
                  </v-list-item-title>
              </v-list-item>
              <v-btn
                  v-if="selectedSubtype.is_multichoice"
                  @click="goToMapStep"
                  :disabled="selectedSubSubtypes.length === 0"
                  class="custom-green-btn"
              >Далі</v-btn>
            </template>
        </v-list>
        <map-selection-view
            v-if="currentStep === 'map'"
            :dialog="dialog"
            @location-selected="handleLocationSelected"
            @updateDialog="closeDialog"
        />
        <div v-if="currentStep === 'notes'" class="center-content">
          <v-textarea
              v-if="selectedType.allies"
              v-model="allies"
              label="Кількість о/с на позиції"
              outlined
              rows="2"
              class="full-width"
          ></v-textarea>

          <v-textarea
              v-if="selectedType.enemies"
              v-model="enemies"
              label="Кільскість o/c противника"
              outlined
              rows="2"
              class="full-width"
          ></v-textarea>

          <v-textarea
              v-model="details"
              label="Деталі"
              outlined
              rows="2"
              class="full-width"
          ></v-textarea>

          <v-btn @click="submitEventWithNotes" class="custom-green-btn-x2">CТВОРИТИ ПОДІЮ</v-btn>

          <div class="time-picker-container">
            <input type="time" id="time_picker" v-bind:value="time" name="time" required />
          </div>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="custom-red-btn" @click="closeDialog">Закрити</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VList,
  VListItem,
  VListItemTitle,
  VCheckbox,
  VRadio,
  VSpacer,
  VTextarea
} from 'vuetify/components';

import eventConfig from '@/config/eventsConfig.json';
import MapSelectionView from '@/components/MapSelectionView.vue';
import {getUserFromLocalStorage, formatMGRS} from "@/services/utils";
import apiClient from "@/services/axios";
import { forward } from "mgrs";
import { useStore } from '@/services/store';
import Position from "@/models/Position";

export default {
  components: {
    VTextarea,
    VBtn,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VDialog,
    VList,
    VListItem,
    VListItemTitle,
    VCheckbox,
    VRadio,
    VSpacer,
    MapSelectionView,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    position: {
      type: Position,
      required: true
    }
  },
  data() {
    return {
      eventConfig,
      currentStep: 'type',
      selectedType: null,
      selectedSubtype: null,
      selectedSubSubtype: null,
      selectedSubSubtypes: [],
      selectedId: -1, // Set default selectedId to -1
      displayedDescription: '',
      allies: '',
      enemies: '',
      details: '',
      time: '',
      coordinates: null,
      azimuth: 0,
      distance: 0,
      dialogTitle: 'Оберіть тип',
      currentSubtypes: [],
      description: '',
      alert: { show: false, message: '', type: 'info' }
    };
  },
  mounted() {
    this.setCurrentTime();
  },
  methods: {
    getTime() {
      const timePicker = document.getElementById('time_picker');
      const [hours, minutes] = timePicker.value.split(':').map(Number);

      if (!this.allowedHours(hours) || !this.allowedMinutes(minutes)) {
        this.showAlert('Невірний час', 'error');
        return;
      }

      return [hours, minutes];
    },
    setCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      this.time = `${hours}:${minutes}`;
    },
    selectType(type) {
      this.selectedType = type;
      this.description = '';
      this.displayedDescription = '';
      this.selectedSubSubtypes = [];

      // Track selected type ID or set to -1 if unavailable
      this.selectedId = type.id || -1;

      this.description += `${type.name}`;
      this.displayedDescription += `${type.displayText !== null && type.displayText !== undefined ? type.displayText : type.name}`;


      if (type.subtypes && type.subtypes.length > 0) {
        this.currentSubtypes = type.subtypes;
        this.currentStep = 'subtype';
        this.dialogTitle = `Оберіть для ${type.name}`;
      } else if (type.skipMapStep) {
        this.azimuth = -1;
        this.distance = -1;
        this.currentStep = 'notes';
        this.dialogTitle = `Додатково для ${type.name}`;
      } else {
        this.currentStep = 'map';
        this.dialogTitle = `Визначте координати для ${type.name}`;
      }
    },

    selectSubtype(subtype) {
      this.selectedSubtype = subtype;
      const isMultichoice = subtype.is_multichoice || false;

      // Track selected subtype ID or set to -1 if unavailable
      this.selectedId = subtype.id || this.selectedId; // Keep previous ID if no subtype ID

      this.description += ` ${subtype.name}`;
      this.displayedDescription += ` ${subtype.displayText !== null && subtype.displayText !== undefined ? subtype.displayText : subtype.name}`;

      if (subtype.subtypes && subtype.subtypes.length > 0) {
        this.currentSubtypes = subtype.subtypes;
        this.currentStep = isMultichoice ? 'sub-subtype' : 'subtype';
        this.dialogTitle = `Оберіть для ${subtype.name}`;
      } else if (this.selectedType.skipMapStep) {
        this.azimuth = -1;
        this.distance = -1;
        this.currentStep = 'notes';
        this.dialogTitle = `Додатково для ${subtype.name}`;
      } else {
        this.currentStep = 'map';
        this.dialogTitle = `Визначте координати для ${subtype.name}`;
      }
    },

    goToMapStep() {
      if (this.selectedSubtype && this.selectedSubtype.is_multichoice) {
        this.description += ` ${this.selectedSubSubtypes.join(', ')}`;
        this.displayedDescription += ` ${this.selectedSubSubtypes
            .map(sub => {
              const foundSubtype = this.selectedSubtype.subtypes.find(s => s.name === sub);
              return foundSubtype ? (foundSubtype.displayText !== null && foundSubtype.displayText !== undefined ? foundSubtype.displayText : foundSubtype.name) : sub;
            })
            .join(', ')}`;
      } else if (this.selectedSubSubtype) {
        this.description += ` ${this.selectedSubSubtype.name}`;
        this.displayedDescription += ` ${this.selectedSubSubtype.displayText !== null && this.selectedSubSubtype.displayText !== undefined ? this.selectedSubSubtype.displayText : this.selectedSubSubtype.name}`;
      }

      if (this.selectedType && this.selectedType.skipMapStep) {
        this.currentStep = 'notes';
        this.dialogTitle = 'Додатково';
        this.azimuth = -1;
        this.distance = -1;
      } else {
        this.currentStep = 'map';
        this.dialogTitle = 'Визначте координати';
      }
    },

    handleLocationSelected(coords) {
      this.azimuth = coords.azimuth;
      this.distance = coords.distance;
      this.coordinates = coords.coordinates;
      this.currentStep = 'notes';
      const name = this.selectedSubSubtype ? this.selectedSubSubtype.name : this.selectedSubtype ? this.selectedSubtype.name : this.selectedType.name;
      this.dialogTitle = `Додатково ${name}`;
    },
    async submitEventWithNotes() {
      if (this.selectedSubSubtype) {
        this.description += `${this.selectedSubSubtype.name}`;
      }
      if (this.selectedSubSubtypes.length > 0) {
        this.description += `${this.selectedSubSubtypes.join(', ')}`;
      }

      const notes = [
        this.selectedType.allies && this.allies.trim() ? `Кількість о/с на позиції: ${this.allies.trim()}` : '',
        this.selectedType.enemies && this.enemies.trim() ? `Кількість о/с противника: ${this.enemies.trim()}` : '',
        this.details.trim() ? `Деталі: ${this.details.trim()}` : ''
      ].filter(Boolean).join(', ');

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const [hours, minutes] = this.getTime();
      const formattedDate = `${year}-${month}-${day} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00.000`;

      const mgrsCoord = this.coordinates && (this.coordinates.lat !== 0 || this.coordinates.lng !== 0)
          ? forward([this.coordinates.lat, this.coordinates.lng])
          : 'N/A';

      const eventData = {
        type: this.selectedType.name,
        description: removeExtraSpaces(this.displayedDescription),
        locationLat: this.coordinates ? this.coordinates.lat : null,
        locationLong: this.coordinates ? this.coordinates.lng : null,
        notes: notes,
        time: formattedDate,
        positionId: this.position.id,
        distance: this.distance,
        azimuth: this.azimuth,
        mgrs: mgrsCoord,
        positionName: this.position.positionName?.trim() ? this.position.positionName : this.position.name,
        isCustomPosition: this.position.isCustom,
        selectedId: this.selectedId // Pass selectedId here, defaulting to -1 if unavailable
      };

      const formattedNotes = notes.startsWith('Деталі:') ? notes.replace(/^Деталі:\s*/, '') : notes;
      const user = getUserFromLocalStorage();
      const formattedMGRS = formatMGRS(mgrsCoord);
      eventData.chatData = `Подія: ${removeExtraSpaces(eventData.description)}
Позиція: ${this.position.name}
Час: ${formattedDate.slice(11, 16)}`;
      formattedNotes && (eventData.chatData += `\nДеталі: ${formattedNotes}`);
      this.azimuth !== -1 && (eventData.chatData += `\nАзимут: ${this.azimuth}`);
      this.distance !== -1 && (eventData.chatData += `\nДистанція: ${this.distance} м`);
      this.coordinates && (this.coordinates.lat !== 0 || this.coordinates.lng !== 0) && (eventData.chatData += `\nMGRS: ${formattedMGRS}`);
      eventData.chatData += `
Черговий: ${user.callsign}` +
          (user.squadron ? `, ${user.squadron.name} ` : '') +
          (user.squadron?.battalion ? `(${user.squadron.battalion.name})` : '');

      console.log('eventData', eventData);
      const result = await this.submitEvent(eventData, this.position, this.selectedType.name);

      if (result.success) {
        this.resetDialog();
        this.$emit('submit', eventData);
        this.$emit('event-success', result.message);
        this.closeDialog();
      } else {
        this.$emit('event-error', result.message);
        this.showAlert(result.message, 'error');
      }

      function removeExtraSpaces(str) {
        return str.replace(/\s+/g, ' ').trim();
      }
    },
    async submitEvent(eventData, position, type) {
        try {
          console.log('type', this.selectedType);
          await apiClient.createEvent(eventData);

          console.log('type', this.selectedType);
          if (!position.isCustom && !(
              eventData.description.includes('FPV') ||
              eventData.description.includes('БПЛА') ||
              eventData.description.includes('КАБ') ||
              eventData.description.includes('НАР') ||
              eventData.description.includes('ПТРК') ||
              eventData.description.includes('Хімзбр'))) {

            const store = useStore();

            console.log('type', this.selectedType);
            // Use configuration flags instead of names
            if (this.selectedType.isUnderAttack !== undefined) {
              position.isUnderAttack = this.selectedType.isUnderAttack;
            }

            if (this.selectedType.isUnderFire !== undefined) {
              position.isUnderFire = this.selectedType.isUnderFire;
            }

            store.addOrUpdatePosition(position);

            await apiClient.editPosition(position.id, {
              isUnderAttack: position.isUnderAttack,
              isUnderFire: position.isUnderFire,
            });

            if (!position.isUnderAttack && !position.isUnderFire) {
              store.removePosition(position.id);
            }
          }
          return { success: true, message: 'Подію успішно створено' };
        } catch (error) {
          console.error(error);
          return { success: false, message: 'Помилка при створенні події' };
        }
    },
    closeDialog() {
      this.resetDialog();
      this.$emit('close');
    },
    resetDialog() {
      this.currentStep = 'type';
      this.selectedType = null;
      this.selectedSubtype = null;
      this.selectedSubSubtype = null;
      this.selectedSubSubtypes = [];
      this.allies = '';
      this.enemies = '';
      this.details = '';
      this.setCurrentTime();
      this.coordinates = null;
      this.distance = 0;
      this.azimuth = 0;
      this.currentSubtypes = [];
      this.description = '';
      this.dialogTitle = 'Select Event Type';
      this.alert.show = false;
    },
    updateDialog(val) {
      this.$emit('update:dialog', val);
    },
    allowedHours(value) {
      return value >= 0 && value <= 23;
    },
    allowedMinutes(value) {
      return value >= 0 && value <= 59;
    },
    showAlert(message, type = 'info') {
      this.alert.message = message;
      this.alert.type = type;
      this.alert.show = true;
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.resetDialog();
      }
    }
  }
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}

.list-item-button {
  background-color: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.list-item-button:hover {
  background-color: #d0d0d0;
}

.full-width {
  width: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-picker-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

#time_picker {
  font-size: 40px;
}
</style>
