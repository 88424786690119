<template>
  <div class="map-container">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="pa-4">
            <v-card-title>
              <h2 class="headline">Список позицій</h2>
            </v-card-title>
            <v-card-text>
              <!-- Alert for errors or missing positions -->
              <v-alert v-if="error" type="error" dense>
                Не вдалося отримати позиції
              </v-alert>
              <v-alert v-else-if="positions.length === 0" type="warning" dense>
                Позиції не встановлено, зверніться до адміністратора, щоб встановити позиції
              </v-alert>

              <!-- Render positions grouped by squadron -->
              <div v-if="Object.keys(groupedPositions).length > 1">
                <!-- If more than one group, render each group with outline and title -->
                <v-card
                    v-for="(positions, squadronName) in groupedPositions"
                    :key="squadronName"
                    class="outlined-card"
                >
                  <v-card-title>
                    <strong>{{ squadronName }}</strong>
                  </v-card-title>
                  <v-list dense>
                    <v-list-item
                        v-for="position in positions"
                        :key="position.id"
                        @click="selectPosition(position)"
                        :class="getItemClass(position)"
                        class="position-item"
                        ripple
                    >
                      <v-list-item-title>{{ position.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ position.description }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="position.isUnderAttack" class="under-attack-text blink">
                        ⚠️ Позицію штурмують
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="position.isUnderFire" class="under-fire-text blink">
                        ⚠️ Позиція під обстрілом
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>

              <!-- If only one group, render positions without outlines or titles -->
              <v-list v-else dense>
                <v-list-item
                    v-for="position in groupedPositions[Object.keys(groupedPositions)[0]]"
                    :key="position.id"
                    @click="selectPosition(position)"
                    :class="getItemClass(position)"
                    class="position-item"
                    ripple
                >
                  <v-list-item-title>{{ position.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ position.description }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="position.isUnderAttack" class="under-attack-text blink">
                    ⚠️ Позицію штурмують
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="position.isUnderFire" class="under-fire-text blink">
                    ⚠️ Позиція під обстрілом
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>

              <v-row class="mt-4">
                <v-col cols="12">
                  <v-text-field
                      v-model="customPositionName"
                      label="Введіть позицію чи квадрат"
                      outlined
                      dense
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn
                      class="custom-green-btn"
                      :disabled="!customPositionName"
                      @click="selectCustomPosition"
                  >
                    Далі
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog for EventType Selection -->
    <v-dialog
        :model-value="showEventTypeDialog"
        @update:model-value="showEventTypeDialog = $event"
        max-width="600px"
    >
      <EventTypeSelectionView
          :dialog="showEventTypeDialog"
          :position="selectedPosition"
          @update:dialog="showEventTypeDialog = $event"
          @event-success="showAlert($event, 'success')"
          @event-error="showAlert($event, 'error')"
          @close="showEventTypeDialog = false"
      />
    </v-dialog>

    <!-- Alert Notification -->
    <v-alert
        v-if="alert.show"
        :type="alert.type"
        dismissible
        @input="alert.show = false"
        class="fixed-top"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import apiClient from '../services/axios';
import EventTypeSelectionView from '../components/EventTypeSelection.vue';
import { useStore } from '@/services/store';
import Position from '@/models/Position';
import { getUserFromLocalStorage } from '@/services/utils';

const positions = ref([]);
const error = ref(false);
const showEventTypeDialog = ref(false);
const selectedPosition = ref(null);
const alert = ref({
  show: false,
  message: '',
  type: 'info'
});
const customPositionName = ref('');

const store = useStore();
let pollingInterval;

// Function to get classes based on position status
function getItemClass(position) {
  if (position.isUnderAttack && position.isUnderFire) {
    return 'position-item-active under-attack under-fire';
  } else if (position.isUnderAttack) {
    return 'position-item-active under-attack';
  } else if (position.isUnderFire) {
    return 'position-item-active under-fire';
  }
  return '';
}

// On mounted, fetch positions and start polling
onMounted(async () => {
  await fetchPositions();
  pollingInterval = setInterval(fetchPositions, 10000); // Poll every 10 seconds
});

// On unmounted, stop polling
onUnmounted(() => {
  clearInterval(pollingInterval);
});

// Fetch positions from the API
async function fetchPositions() {
  try {
    const response = await apiClient.getPositions();
    const newPositions = response.data.map((positionData) => new Position(positionData));

    if (JSON.stringify(newPositions) !== JSON.stringify(positions.value)) {
      positions.value = newPositions;
      syncStoreWithPositions(newPositions);
    }

    error.value = false;
  } catch (err) {
    error.value = true;
  }
}

// Sync the store with the positions
function syncStoreWithPositions(newPositions) {
  const currentActivePositions = store.activePositions.map((pos) => pos.id);

  newPositions.forEach((position) => {
    if (position.isUnderAttack || position.isUnderFire) {
      if (!currentActivePositions.includes(position.id)) {
        store.addOrUpdatePosition(position);
      }
    } else {
      if (currentActivePositions.includes(position.id)) {
        store.removePosition(position.id);
      }
    }
  });
}

// Group positions by squadron.name, exclude positions without a squadron, and sort them
const groupedPositions = computed(() => {
  return positions.value
      .filter((position) => position.squadron) // Filter out positions without squadron
      .reduce((acc, position) => {
        const squadronName = position.squadron.name;

        if (!acc[squadronName]) {
          acc[squadronName] = [];
        }

        acc[squadronName].push(position);

        // Sort positions in each group, with those under attack/fire at the top
        acc[squadronName].sort((a, b) => {
          if (a.isUnderAttack && a.isUnderFire) return -1;
          if (b.isUnderAttack && b.isUnderFire) return 1;

          if (a.isUnderAttack) return -1;
          if (b.isUnderAttack) return 1;

          if (a.isUnderFire) return -1;
          if (b.isUnderFire) return 1;

          return a.name.localeCompare(b.name);
        });

        return acc;
      }, {});
});

// Select a position to open the event type dialog
function selectPosition(position) {
  selectedPosition.value = position;
  showEventTypeDialog.value = true;
}

// Select a custom position
function selectCustomPosition() {
  selectedPosition.value = new Position({
    name: customPositionName.value,
    positionName: customPositionName.value,
    description: 'Користувацька позиція',
    isUnderAttack: false,
    isUnderFire: false,
    isCustom: true,
    squadronId: getUserFromLocalStorage().squadronId,
    squadron: getUserFromLocalStorage().squadron,
  });
  showEventTypeDialog.value = true;
}

// Show alert for events
function showAlert(message, type) {
  alert.value = {
    show: true,
    message,
    type,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}
</script>

<style scoped>
.map-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  background-color: #f5f5f5;
}

h2 {
  margin-bottom: 16px;
}

.position-item {
  transition: background-color 0.3s;
}

.position-item-active.under-attack {
  animation: blink-bg-attack 1.5s infinite;
}

.position-item-active.under-fire {
  animation: blink-bg-fire 1.5s infinite;
}

.position-item-active.under-attack.under-fire {
  animation: blink-bg-both 1.5s infinite;
}

.position-item:hover {
  background-color: #f0f0f0;
}

.under-attack-text,
.under-fire-text {
  color: red;
  font-weight: bold;
}

.custom-green-btn {
  background-color: #4caf50;
  color: white;
  width: 100%;
}

.outlined-card {
  border: 1px solid #ddd;
  margin-bottom: 16px;
  padding: 8px;
}

@keyframes blink-bg-attack {
  0%, 50%, 100% {
    background-color: #ffcccc;
  }
  25%, 75% {
    background-color: transparent;
  }
}

@keyframes blink-bg-fire {
  0%, 50%, 100% {
    background-color: #ffcccc;
  }
  25%, 75% {
    background-color: transparent;
  }
}

@keyframes blink-bg-both {
  0%, 50%, 100% {
    background-color: #ff9999;
  }
  25%, 75% {
    background-color: transparent;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  width: 90%;
  max-width: 500px;
}
</style>
