<template>
  <v-dialog :model-value="dialog" @update:model-value="updateDialog" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Визначте координати</span>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="activePanel" accordion>
          <!-- Distance and Azimuth Panel -->
          <v-expansion-panel>
            <v-expansion-panel-title class="header">
              <span class="header-text">Азимут та дистанція події</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <!-- Distance Selector -->
              <div class="distance-section">
                <div>Дистанція: {{ distance }} m</div>
                <distance-slider
                    v-model="distance"
                    :tick-marks="[
                    { value: 0, label: '0' },
                    { value: 2500, label: '1500' },
                    { value: 5000, label: '>5000' },
                  ]"
                />
              </div>
              <!-- Azimuth Selector -->
              <div class="azimuth-section">
                <svg class="azimuth-circle" viewBox="0 0 200 200" @click="setAzimuth">
                  <!-- Dotted lines -->
                  <line
                      v-for="angle in markerAngles"
                      :key="angle"
                      :x1="100"
                      :y1="100"
                      :x2="100 + 80 * Math.cos((angle - 90) * Math.PI / 180)"
                      :y2="100 + 80 * Math.sin((angle - 90) * Math.PI / 180)"
                      stroke="black"
                      stroke-dasharray="4"
                  />
                  <!-- Degree markers -->
                  <text
                      v-for="angle in markerAngles"
                      :key="'text' + angle"
                      :x="100 + 90 * Math.cos((angle - 90) * Math.PI / 180)"
                      :y="100 + 90 * Math.sin((angle - 90) * Math.PI / 180)"
                      font-size="12"
                      text-anchor="middle"
                      dominant-baseline="middle"
                  >
                    {{ angle }}
                  </text>
                  <!-- Azimuth arrow with head -->
                  <line
                      :x1="100"
                      :y1="100"
                      :x2="100 + 80 * Math.cos((azimuth - 90) * Math.PI / 180)"
                      :y2="100 + 80 * Math.sin((azimuth - 90) * Math.PI / 180)"
                      stroke="black"
                      stroke-width="2"
                  />
                  <polygon
                      :points="getArrowHeadPoints()"
                      fill="black"
                      :transform="'rotate(180 ' + (100 + 80 * Math.cos((azimuth - 90) * Math.PI / 180)) + ' ' + (100 + 80 * Math.sin((azimuth - 90) * Math.PI / 180)) + ')'"
                  />
                </svg>
                <v-text-field
                    label="Азимут"
                    v-model="azimuth"
                    type="number"
                    :min="0"
                    :max="359"
                    class="azimuth-input"
                    hide-details
                    @change="onAzimuthChange"
                ></v-text-field>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <!-- Map Panel -->
          <v-expansion-panel>
            <v-expansion-panel-title class="header">
              <span class="header-text">Вибрати на мапі</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="map-container">
                <l-map
                    :zoom="zoom"
                    :center="center"
                    ref="map"
                    @ready="onMapReady"
                    @click="onMapClick"
                    style="height: 500px; width: 100%;"
                >
                  <l-tile-layer
                      :url="imageryUrl"
                      :attribution="imageryAttribution"
                  ></l-tile-layer>
                  <l-tile-layer
                      :url="labelsUrl"
                      :attribution="labelsAttribution"
                  ></l-tile-layer>
                  <l-marker v-if="marker" :lat-lng="marker"></l-marker>
                </l-map>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn class="custom-default-btn" @click="skip">Пропустити</v-btn>
        <v-btn class="custom-green-btn" @click="proceed">Далі</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import { LMap, LTileLayer, LMarker } from "vue3-leaflet";
import "leaflet/dist/leaflet.css";
import DistanceSlider from "./DistanceSlider.vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    DistanceSlider,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const activePanel = ref(0); // Default to first panel open (Azimuth and Distance)
    const zoom = ref(15);
    const center = ref([48.0159416, 37.7962839]);
    const marker = ref(null);
    const map = ref(null);

    const distance = ref(0); // Default distance
    const azimuth = ref(0); // Default azimuth

    const imageryUrl =
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
    const imageryAttribution =
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
    const labelsUrl =
        "https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}";
    const labelsAttribution = "Tiles &copy; Esri &mdash; Source: Esri";

    const markerAngles = [0, 90, 180, 270]; // Corrected degree markers

    const onMapReady = (mapInstance) => {
      map.value = mapInstance;
      loadLastLocation();
    };

    const loadLastLocation = () => {
      const lastLocation = JSON.parse(localStorage.getItem("lastLocation"));
      if (lastLocation) {
        center.value = [lastLocation.lat, lastLocation.lng];
        panToLocation(center.value);
      } else {
        getUserLocation();
      }
    };

    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              const userLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              center.value = [userLocation.lat, userLocation.lng];
              panToLocation(center.value);
            },
            () => {
              alert(
                  "Неможливо отримати ваші координати, оберіть точку вручну"
              );
            }
        );
      }
    };

    const panToLocation = (location) => {
      if (map.value) {
        map.value.setView(location, zoom.value);
      }
    };

    const onMapClick = (event) => {
      marker.value = event.latlng;
      center.value = [event.latlng.lat, event.latlng.lng];
      localStorage.setItem("lastLocation", JSON.stringify(marker.value));
    };

    const onAzimuthChange = () => {
      if (azimuth.value < 0) azimuth.value = 0;
      if (azimuth.value > 359) azimuth.value = 359;
    };

    const setAzimuth = (event) => {
      const circleRect = event.target.getBoundingClientRect();
      const centerX = circleRect.left + circleRect.width / 2;
      const centerY = circleRect.top + circleRect.height / 2;
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      const deltaX = mouseX - centerX;
      const deltaY = mouseY - centerY;

      const radians = Math.atan2(deltaY, deltaX); // Calculate angle
      let degrees = radians * (180 / Math.PI);

      degrees = (degrees + 90 + 360) % 360; // Adjusted normalization to align with top (0°)
      azimuth.value = Math.round(degrees);
    };

    const getArrowHeadPoints = () => {
      const length = 80;
      const arrowSize = 10; // Increased size for better visibility
      const angle = azimuth.value - 90; // Adjustment for arrow direction
      const x = 100 + length * Math.cos((angle * Math.PI) / 180);
      const y = 100 + length * Math.sin((angle * Math.PI) / 180);

      const leftX = x - arrowSize * Math.cos(((angle + 150) * Math.PI) / 180);
      const leftY = y - arrowSize * Math.sin(((angle + 150) * Math.PI) / 180);
      const rightX = x - arrowSize * Math.cos(((angle - 150) * Math.PI) / 180);
      const rightY = y - arrowSize * Math.sin(((angle - 150) * Math.PI) / 180);

      return `${x},${y} ${leftX},${leftY} ${rightX},${rightY}`;
    };

    const skip = () => {
      const skippedData = {
        distance: -1,
        azimuth: -1,
        coordinates: { lat: 0, lng: 0 },
      };
      emit("location-selected", skippedData);
    };

    const proceed = () => {
      const selectedData = {
        distance: distance.value,
        azimuth: azimuth.value,
        coordinates: marker.value || { lat: 0, lng: 0 },
      };
      emit("location-selected", selectedData);
    };

    const closeDialog = () => {
      updateDialog(false);
    };

    const updateDialog = (val) => {
      emit("updateDialog", val);
    };

    // Watch for changes on activePanel
    watch(activePanel, async (newVal) => {
      if (newVal === 1) {
        await nextTick(); // Ensure DOM updates are applied
        setTimeout(() => {
          if (map.value) {
            map.value.invalidateSize(); // Call Leaflet's invalidateSize
          }
        }, 500); // Delay to ensure transitions are complete
      }
    });

    return {
      activePanel,
      zoom,
      center,
      marker,
      distance,
      azimuth,
      imageryUrl,
      imageryAttribution,
      labelsUrl,
      labelsAttribution,
      onMapClick,
      skip,
      proceed,
      closeDialog,
      onMapReady,
      onAzimuthChange,
      setAzimuth,
      markerAngles,
      getArrowHeadPoints,
    };
  },
};
</script>

<style scoped>
.map-container {
  height: 500px;
  width: 100%;
}

.distance-section {
  margin-bottom: 16px;
}

.azimuth-section {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.azimuth-circle {
  width: 100%;
  max-width: 220px; /* Set max-width to ensure it doesn't exceed desired size */
  height: auto;
  aspect-ratio: 1 / 1; /* Maintain aspect ratio to keep it circular */
  border-radius: 50%;
  border: 2px solid black;
  position: relative;
  margin-right: 16px;
  cursor: pointer;
}

.azimuth-input {
  width: 100px;
}

.header {
  background-color: rgb(6, 26, 40);
  color: white;
}

.header-text {
  font-size: 16px;
  font-weight: bold;
}
</style>