<template>
  <v-dialog :model-value="dialogVisible" @update:model-value="handleCloseDialog" max-width="600px">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Події для чату {{ chatName }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="ma-5"
        ></v-progress-circular>

        <v-treeview
            v-else
            :key="selectionKey"
            v-model:selected="selection"
            :items="items"
            select-strategy="classic"
            item-title="name"
            item-value="id"
            return-object
            selectable
            dense
            @update:selected="onSelectionUpdate"
        ></v-treeview>
      </v-card-text>

      <v-card-actions>
        <v-btn class="custom-red-btn" @click="handleCloseDialog">Відмінити</v-btn>
        <v-btn class="custom-green-btn" @click="saveConfig">Зберегти</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import chatsConfig from '../config/chatsConfig.json';

export default {
  props: {
    chatName: {
      type: String,
      required: true
    },
    config: {
      type: Array,
      default: null
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    battalion: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selection: [],
      items: [],
      loading: true,
      selectionKey: 0,
    };
  },
  async mounted() {
    this.loadAndPrepareData();

    this.loading = false;
  },
  methods: {
    loadAndPrepareData() {
      this.items = chatsConfig;

      let preselectConfig = [];

      if (this.battalion) {
        const signalConfig = this.battalion.signalChatsData?.config || [];
        const whatsappConfig = this.battalion.whatsappChatsData?.config || [];
        preselectConfig = [...signalConfig, ...whatsappConfig];
      } else if (this.config !== null) {
        preselectConfig = this.config;
      } else {
        this.selectAllNodes(this.items);
        return;
      }

      this.preselectNodes(preselectConfig);
    },
    onSelectionUpdate(newSelection) {
      console.log("Event 'update:selected': Selection updated via treeview event");
      console.log("Old seelction", this.selection);
      console.log("New selection from event:", JSON.stringify(newSelection, null, 2));
    },
    saveConfig() {
      const selectedNodes = this.selection.map(node => ({
        id: node.id,
        name: node.name
      }));

      this.$emit('save', selectedNodes);
    },
    handleCloseDialog() {
      this.$emit('close');
    },
    preselectNodes(config) {
      const validConfig = config.filter(item => item && item.id !== undefined && item.id !== null);
      const selectedIds = validConfig.map(item => item.id);
      const selectNodes = (nodes) => {
        nodes.forEach(node => {
          if (selectedIds.includes(node.id)) {
            if (!this.selection.find(selectedNode => selectedNode.id === node.id)) {
              this.selection.push(node);
            }
          }
          if (node.children && node.children.length > 0) {
            selectNodes(node.children);
          }
        });
      };

      selectNodes(this.items);

      this.selection = [...this.selection];
      this.selectionKey++;
      this.$nextTick(() => {
        this.items = [...this.items];
      });
    },
    selectAllNodes(nodes) {
      const selectAll = (nodes) => {
        nodes.forEach(node => {
          if (!this.selection.find(selectedNode => selectedNode.id === node.id)) {
            this.selection.push(node);
          }
          if (node.children && node.children.length > 0) {
            selectAll(node.children);
          }
        });
      };

      selectAll(nodes);

      this.selectionKey++;
      this.$nextTick(() => {
        this.items = [...this.items];
      });
    }
  }
};
</script>
